* {
  box-sizing: border-box;
}

.opennedContainer {
  font-family: sans-serif;
  position: fixed;
  left: 30px;
  bottom: 30px;
  width: 337px;
  height: 663px;
  border-radius: 8px;
  box-shadow: #333c52 0 3px 8px;
  transition: transform 0.2s;
  z-index: 1;
}

.container {
  font-family: sans-serif;
  position: fixed;
  left: 30px;
  bottom: 30px;
  width: 100px;
  height: 200px;
  border-radius: 8px;
  box-shadow: #333c52 0 3px 8px;
  transition: transform 0.2s;
  z-index: 1;
  cursor: pointer;
  &:hover .play-button-icon {
    transform: scale(1.07);
  }
  &:hover {
    transform: scale(1.07);
  }
}

.react-player video {
  object-fit: cover;
  border-radius: 8px;
}

.play-button {
  background: transparent;
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 0;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.play-button-icon {
  width: 25px;
  height: 25px;
  transition: transform 0.2s;
}

.info {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 15px 15px 20px;
  cursor: pointer;
}

.info-container {
  display: flex;
  height: 100%;
}

.controls {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 10px;
}

.control-buttons {
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
  display: block;
}

.controls-play {
  width: 25px;
  height: 25px;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.07);
  }
}

.controls-volume {
  width: 25px;
  height: 25px;
  transition: transform 0.2s;
  &:hover {
    transform: scale(1.1);
  }
}

.description {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.title {
  margin: 0;
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 21px;
  color: #fff;
}

.description-text {
  font-size: 13px;
  color: #fff;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
  line-height: 1.6;
  padding-right: 15px;
}

.description-btn {
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 16px;
  background-color: #1b5cf3;
  color: #fff;
  border-radius: 10px;
  border: none;
  cursor: pointer;
  width: 100%;
}
